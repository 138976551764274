import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { environment } from '@env/environment';
declare global {
    interface Window {
        initMap: () => void;
    }
}

@Component({
    selector: 'azd-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false,
})
export class AppComponent implements OnInit {
    title = 'azw-digital-frontend';

    constructor(
        private translate: TranslateService,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.translate.setDefaultLang('it');
    }

    ngOnInit(): void {
        const script = this.renderer.createElement('script');
        const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&callback=initMap&libraries=places&language=it`;

        this.renderer.setAttribute(script, 'src', scriptSrc);
        this.renderer.setAttribute(script, 'nonce', 'nonce-randomNonceGoesHere');

        this.renderer.appendChild(this.document.head, script);

        window.initMap = (): void => {};
    }
}
